import Vue from 'vue'
import Vuex from 'vuex'

import order from '@/plugins/app/order/store/order.store'
import wAuth from '@/plugins/w/authStore/wAuth.store'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {
		wAuth,
		order
	}
})

export default store
