/**
 * Function used to merge multiple order registrations into one. Min and max start_date and end_date will be determinated.
 * @param orderRegistrations
 * @returns orderRegistration
 */
export const mergeOrderRegistrations = (orderRegistrations, orderType) => {
	const orderRegistrationDates = orderRegistrations.reduce((ord, or) => {
		or.order_registration_dates.forEach((d) => {
			if (ord.some((date) => date.date == d.date)) return
			ord.push(d)
		})
		return ord
	}, [])

	const occupiedDatesMerged = [],
		availableDatesMerged = []
	orderRegistrations.forEach((or) => {
		or.product_period.occupied_dates.forEach((date) => {
			if (!occupiedDatesMerged.includes(date)) occupiedDatesMerged.push(date)
		})

		or.product_period.available_dates.forEach((date) => {
			if (!availableDatesMerged.includes(date)) availableDatesMerged.push(date)
		})
	})

	// find lowest start_date and highest end_date
	let min = orderRegistrations[0].product_period.start_date
	let max = orderRegistrations[0].product_period.end_date
	orderRegistrations.forEach((or) => {
		const currentStartDate = new Date(or.product_period.start_date)
		const currentEndDate = new Date(or.product_period.end_date)
		if (currentStartDate.getTime() < new Date(min).getTime()) min = or.product_period.start_date
		if (currentEndDate.getTime() > new Date(max).getTime()) max = or.product_period.end_date
	})

	const result = {
		...orderRegistrations[0],
		order_registration_dates: orderRegistrationDates
	}

	result.product_period = {
		...result.product_period,
		occupied_dates: occupiedDatesMerged,
		available_dates: availableDatesMerged,
		start_date: min,
		end_date: max
	}
	if (orderType == 'gamecamp' || orderType == 'sgd') result.product_period.is_allowed_select_day = '0'

	return result
}
