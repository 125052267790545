import skLang from './lang/sk.json'

function translate(sentence) {
	if (skLang[sentence]) {
		return skLang[sentence]
	} else {
		return sentence
	}
}

function parseErrorObj(error) {
	const out: string[] = []

	Object.values(error).forEach((m) => {
		out.push(translate(m))
	})

	return out.join('<br>')
}

export default {
	parseToString(data) {
		if (typeof data == 'string') {
			return translate(data)
		} else if (data && data.error && typeof data.error == 'string') {
			return translate(data.error)
		} else if (data && data.error && typeof data.error == 'object') {
			return parseErrorObj(data.error)
		} else if (data.data && data.data.error && typeof data.data.error == 'string') {
			return translate(data.data.error)
		} else if (data && data.data && data.data.error && typeof data.data.error == 'object') {
			return parseErrorObj(data.data.error)
		} else if (data.statusText) {
			return translate(data.statusText)
		} else {
			return translate(data)
		}
	}
}
