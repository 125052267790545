import moxios from '@/plugins/w/axios-mocks'

localStorage.setItem('isMoxios', 'false')

moxios.mock({
	'POST v1/auth/forgot': true,
	'POST v1/auth/login': true,
	'POST v1/auth/signup': true,
	'POST v1/auth/refresh': true,
	'POST v1/auth/reset': true,
	'POST v1/auth/invalidate': true,
	'POST v1/auth/info': true,

	'POST discounts/coupons': true,

	'POST v1/orders': true,
	'POST v1/orders/payments': true,
	'GET v1/orders/(orderId)': true,
	'PUT v1/orders/(orderId)': true,
	'PUT v1/orders/(orderId)/attach': true,

	'GET v1/products': true
})
