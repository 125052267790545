import axios from 'axios'
import store from '@/store'
import router from '@/plugins/app/_config/router'
import wAuthConfig from '@/plugins/w/config/wAuth.config'

let isRefreshing = false
let refreshHook = Promise.resolve(null)

async function _unauthorised(error) {
	if (!error.response || error.response.status != 401 || error.config.url == 'v1/auth/login') {
		return Promise.reject(error)
	}

	if (!isRefreshing) {
		refreshHook = new Promise<null>((resolve, reject) => {
			store
				.dispatch('wAuth/refreshToken')
				.then((token) => resolve(token))
				.catch((error) => reject(error))
				.finally(() => (isRefreshing = false))
		})

		isRefreshing = true
	}

	try {
		const token = await refreshHook
		error.config.headers = {
			Authorization: `bearer ${token}`
		}

		return Promise.resolve(await axios.request(error.config))
	} catch (error) {
		console.error(error)
		store.commit('wAuth/logout')
		router.push({ name: wAuthConfig.CONFIG.tokenExpiredRedirectRouteName })
	}
}

axios.interceptors.response.use(
	(response) => response,
	(err) => _unauthorised(err)
)
