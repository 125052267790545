import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { ref } from '@vue/composition-api'
import utils from './utils'
import VueToast from 'vue-toast-notification'
import './css/toast.css'

Vue.use(VueToast)
Vue.use(VueCompositionAPI)

export default {
	_isLoading: ref(false),

	isLoading() {
		return this._isLoading.value
	},
	stopLoading() {
		this._isLoading.value = false
	},
	error(error = 'Error') {
		this._isLoading.value = false

		Vue.$toast.open({
			message: utils.parseToString(error),
			type: 'error',
			position: 'top',
			duration: 5000
		})
	},
	success(message = 'Success') {
		this._isLoading.value = false

		Vue.$toast.open({
			message: message,
			type: 'success',
			position: 'top',
			duration: 3000
		})
	},
	// NOTE: toto sa ma volat len pre formulare, kde treba pred odoslanim clearnut errory a kde zaroven nastavujeme disabled button kvoli uiLoading statu, nedava zmysel volat to v created..
	clear_loading() {
		this._isLoading.value = true
		Vue.$toast.clear()
	},
	clear() {
		Vue.$toast.clear()
	}
}
